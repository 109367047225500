import { BooleanFeatureFlags } from './feature-flags.types'

class FeatureFlagsService {
	private static instance: FeatureFlagsService
	private flags: Record<string, boolean | number> = {}

	private constructor() { }

	public static getInstance(): FeatureFlagsService {
		if (!FeatureFlagsService.instance) {
			FeatureFlagsService.instance = new FeatureFlagsService()
		}
		return FeatureFlagsService.instance
	}

	public setFlags(flags: Record<string, boolean | number>) {
		this.flags = flags
	}

	public getFlag(flagName: BooleanFeatureFlags | string): boolean {
		return !!this.flags[flagName]
	}
}

export default FeatureFlagsService.getInstance() 
