export enum BooleanFeatureFlags {
    ABANDONED_CART_CAMPAIGN = 'abandonedCartCampaign',
    ACCOUNT_PAGE = 'accountPage',
    ALLOW_LEGACY_SCREEN_ROUTING_APP_STUDIO = 'allowLegacyScreenRoutingInAppstudio',
    APP_CLIPS = 'appClips',
    APP_STUDIO_ACCOUNT = 'appStudioAccount',
    APP_STUDIO_AUTHENTICATION_SCREEN = 'appStudioAuthenticationScreen',
    APP_STUDIO_CART = 'appStudioCart',
    APP_STUDIO_CART_CUSTOM_SCREEN = 'appStudioCartCustomScreen',
    APP_STUDIO_CONDITIONAL_TAGGING = 'appStudioConditionalTagging',
    APP_STUDIO_CUSTOM_SCREENS = 'appStudioCustomScreens',
    APP_STUDIO_EDITOR = 'appStudioEditor',
    APP_STUDIO_HOME = 'appStudioHome',
    APP_STUDIO_NATIVE_RENDERING = 'appStudioNativeRendering',
    APP_STUDIO_NAVIGATION = 'appStudioNavigation',
    APP_STUDIO_PDP = 'appStudioPdp',
    APP_STUDIO_PLP = 'appStudioPlp',
    APP_STUDIO_POST_PURCHASE = 'appStudioPostPurchase',
    APP_STUDIO_SEARCH = 'appStudioSearch',
    APP_STUDIO_SHOW_LEGACY_ACCOUNT_PAGE = 'appStudioShowLegacyAccountPage',
    APP_STUDIO_SHOW_LEGACY_CART_PAGE = 'appStudioShowLegacyCartPage',
    APP_STUDIO_SHOW_LEGACY_COLLECTIONS_PAGE = 'appStudioShowLegacyCollectionsPage',
    APP_STUDIO_RESET_PASSWORD = 'appStudioResetPassword',
    APP_STUDIO_WISHLIST_PASSWORD = 'appStudioWishlistDetail',
    ASPECT_RATIOS = 'aspectRatios',
    AUTOMATED_PUSH_CHART = 'automatedPushChart',
    BADGES = 'badges',
    BLOCK_APP_STUDIO_CUSTOM_SCREEN_MIGRATION = 'blockAppStudioCustomScreenMigration',
    BLOCK_BASED_SCREENS = 'blockBasedScreens',
    BRAND_KIT = 'brandKit',
    CAPTURE_KIT = 'captureKit',
    CHARGEBEE_MODAL = 'chargebeeModal',
    COLLECTION_CUSTOM_METAFIELDS = 'collectionCustomerMetafields',
    CREATE = 'create',
    CUSTOM_BLOCKS = 'customBlocks',
    CUSTOM_BLOCKS_V2 = 'customBlocksV2',
    CUSTOM_CART_PAGE = 'customCartPage',
    CUSTOM_PUSH = 'customPush',
    CUSTOM_PUSH_DEEP_LINKING = 'customPushDeepLinking',
    CUSTOM_SCREEN_PAGE = 'customScreenPage',
    CUSTOM_VARIANT_PICKER = 'customVariantPicker',
    DATA_OPT_OUT_DESTINATION = 'dataOptOutDestination',
    DOWNGRADE_INTEGRATION_RELEASE = 'downgradeIntegrationRelease',
    DOWGRADES_V2 = 'downgradesV2',
    DROPS_PREVIEW = 'dropsPreview',
    DYNAMIC_PRODUCT_SETTINGS = 'dynamicProductSettings',
    INSIGHTS_PRO = 'insightsPro',
    INSIGHTS_PRO_ORDER_WEBHOOK_DATA_MIGRATION = 'insightsProOrderWebhookDataMigration',
    INSIGHTS_PRO_POLARIS = 'insightsProPolaris',
    INSIGHTS_PRO_SESSION_DATA_MIGRATION = 'insightsProSessionDataMigration',
    LIMITED_ACCESS = 'limitedAccess',
    MOBILE_DOWNLOAD_BANNER_MIGRATION = 'mobileDownloadBannerMigration',
    NAVIGATION_TRANSLATIONS = 'navigationTranslations',
    NEW_NAVIGATION = 'newNavigation',
    NEW_PUSH_PAGE = 'newPushPage',
    NOSTO_GRID_BLOCK = 'nostoGridBlock',
    ORDER_TRACKING_CAMPAIGN = 'orderTrackingCampaign',
    PARTNER_BLOCKS_BANK = 'partnerBlocksBank',
    PBP_ADDONS = 'pbpAddons',
    PDP_UPGRADES = 'pdpUpgrades',
    POLARIS_FOR_PUSH_EXPLORER = 'polarisForPushExplorer',
    POST_PURCHASE_CAMPAIGN = 'postPurchaseCampaign',
    PUSH_IPHONE_PREVIEW_STYLE = 'pushIPhonePreviewStyle',
    PUSH_TAP_GPT = 'pushTapGpt',
    READ_ALL_ORDERS_SCOPE_BANNER = 'readAllOrdersScopeBanner',
    SELF_SERVICE_FONTS_ENHANCEMENTS = 'selfServiceFontsEnhancements',
    SELF_SERVICE_ICONS = 'selfServiceIcons',
    SELF_SERVICE_ICONS_ENHANCEMENTS = 'selfServiceIconsEnhancements',
    TOP_NAV_BAR_REDESIGN = 'topNavBarRedesign',
    USER_GATING_CART = 'userGatingCart',
    WINBACK_CAMPAIGN = 'winbackCampaign',
    SEGMENTATION_MODAL = 'segmentationModal',
    ENGAGE_PAGE_REDESIGN = 'engagePageRedesign',
    SEGMENTATION_CREATE_AND_EDIT = 'segmentationCreateAndEdit',
    APP_STUDIO_CONDITIONALS_V2 = 'appStudioConditionalsV2',
    EXPERIMENTAL_SEGMENT_OPTIONS = 'experimentalSegmentOptions',
    INTENTIONAL_DRAFT_CREATION_MODALS = 'intentionalDraftCreationModals',
    CONDITIONALS_PREVIEWER = 'conditionalsPreviewer',
    DISPLAY_APP_STUDIO_MULTIPAGES = 'displayAppStudioMultipages',
}

export enum NumberFeatureFlags {
    PUSH_EXPLORER_RELEVANCE_PERIOD = 'pushExplorerRelevancePeriod',
}

export type FeatureFlagBoolValues = {
    [key in BooleanFeatureFlags]: boolean
}

export type FeatureFlagNumberValues = {
    [key in NumberFeatureFlags]: number
}

export type FeatureFlagValues = FeatureFlagBoolValues & FeatureFlagNumberValues
