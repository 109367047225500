const flattenObject = (obj, parentKey = '', result = {}) => {
    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
            // Recursively flatten nested objects, but skip the "columns" key
            if (key !== 'columns') {
                flattenObject(
                    obj[key],
                    `${parentKey}${parentKey ? '.' : ''}${key}`,
                    result
                )
            } else {
                flattenObject(obj[key], parentKey, result) // Skip adding "columns" to the path
            }
        } else if (
            key === 'dataType' ||
            key === 'areRequiredFields' ||
            key === 'label' ||
            key === 'defaultValue'
        ) {
            // Initialize the result[parentKey] object if it doesn't exist
            if (!result[parentKey]) {
                result[parentKey] = {}
            }
            // Add or update the property in the existing object
            result[parentKey][key] = obj[key]
        }
    })
    return result
}

const flattenSchema = (schema) =>
    Object.keys(schema).flatMap((table) => {
        const tableColumns = schema[table].columns
        return flattenObject(tableColumns, table)
    })
export { flattenObject, flattenSchema }
