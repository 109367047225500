import {
    createAsyncThunk,
    createSlice,
    SerializedError,
} from '@reduxjs/toolkit'
import _ from 'lodash'
import { RootState } from 'store'
import getHeaders from 'store/_utils/get-headers'
import { createPushPayload } from 'store/Notifications/helpers'
import { Segment, SegmentConfig } from 'types/api-types'
import { flattenObject } from './helpers'

const segmentationURL = `${
    import.meta.env.VITE_TC_BACKEND_API as string
}/custom-segment`

const pushSegmentationURL = `${
    import.meta.env.VITE_TC_BACKEND_API as string
}/push/custom-segment`

const segmentConfigUrl = `${
    import.meta.env.VITE_TC_BACKEND_API as string
}/semantic-configs`

interface SegmentationState {
    segments: Segment[]
    segmentConfig: SegmentConfig
    loading: 'idle' | 'pending' | 'fufilled' | 'rejected'
    error: SerializedError | null
    segmentSize: { segmentSize: number; maxSegmentSize: number } | null
}

const initialState: SegmentationState = {
    segments: [],
    segmentConfig: {},
    loading: 'idle',
    error: null,
    segmentSize: null,
}

const getSegmentationHeaders = async (state: RootState) => {
    const headers = await getHeaders()
    const appId = state.app.data.id

    return {
        ...headers,
        'Content-Type': 'application/json',
        'app-id': appId,
    }
}

export const sendSegmentedPushNotification = createAsyncThunk(
    'segmentation/sendSegmentedPushNotification',
    async (segment: Segment, { getState }) => {
        const state = getState() as RootState
        const appId = state.app.data.id
        const headers = await getSegmentationHeaders(state)
        const body = createPushPayload(segment, appId)
        const response = await fetch(`${pushSegmentationURL}`, {
            method: 'POST',
            headers,
            body: JSON.stringify(body),
        })
        if (!response.ok) throw new Error('Failed to send segmented push.')
        return await response.json()
    }
)

export const createSegment = createAsyncThunk(
    'segmentation/createSegment',
    async (segment: Segment, { getState, dispatch }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const body = JSON.stringify(segment)
        const response = await fetch(`${segmentationURL}`, {
            method: 'POST',
            headers,
            body,
        })
        if (!response.ok) throw new Error('Failed to create segment.')
        const newSegment = await response.json()
        dispatch(addSegment(newSegment))
        return newSegment
    }
)

export const deleteSegment = createAsyncThunk(
    'segmentation/deleteSegment',
    async (segmentId: string, { getState, dispatch }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const response = await fetch(`${segmentationURL}/${segmentId}`, {
            method: 'DELETE',
            headers,
        })
        if (!response.ok) throw new Error('Failed to delete segment.')
        dispatch(removeSegment(segmentId))
        return segmentId
    }
)

export const updateSegment = createAsyncThunk(
    'segmentation/updateSegment',
    async (segment: Segment, { getState, dispatch }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const body = JSON.stringify(segment)
        const response = await fetch(`${segmentationURL}/${segment.id}`, {
            method: 'PUT',
            headers,
            body,
        })
        if (!response.ok) throw new Error('Failed to update segment.')
        const updatedSegment = await response.json()

        dispatch(editSegment(updatedSegment))
        return updatedSegment
    }
)

export const fetchSegments = createAsyncThunk(
    'segmentation/fetchSegments',
    async (_, { getState }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const response = await fetch(`${segmentationURL}`, {
            method: 'GET',
            headers,
        })
        if (!response.ok) throw new Error('Failed to fetch segments.')
        return await response.json()
    }
)

export const fetchSegmentConfig = createAsyncThunk(
    'segmentation/fetchSegmentConfigs',
    async (_, { getState }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const response = await fetch(`${segmentConfigUrl}`, {
            method: 'GET',
            headers,
        })
        if (!response.ok)
            throw new Error('Failed to fetch segment segment configs.')
        return await response.json()
    }
)

export const fetchSegmentSize = createAsyncThunk(
    'segmentation/fetchSegmentSize',
    async (segment: Segment, { getState }) => {
        const state = getState() as RootState
        const headers = await getSegmentationHeaders(state)
        const response = await fetch(`${segmentationURL}/size`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ ...segment, pushOptedIn: true }),
        })
        if (!response.ok) throw new Error('Failed to fetch segment size.')
        return await response.json()
    }
)

export const segmentationSlice = createSlice({
    name: 'segmentation',
    initialState,
    reducers: {
        addSegment: (state, action) => {
            state.segments.push(action.payload)
        },
        removeSegment: (state, action) => {
            state.segments = state.segments.filter(
                (segment) => segment.id !== action.payload
            )
        },
        editSegment: (state, action) => {
            const index = state.segments.findIndex(
                (segment) => segment.id === action.payload.id
            )
            if (index !== -1) {
                state.segments[index] = action.payload
            }
        },
        clearSegmentSize: (state) => {
            state.segmentSize = null
        },
    },
    extraReducers: (builder) => {
        // Fetch Segments Reducers
        builder.addCase(fetchSegments.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(fetchSegments.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.segments = action.payload
        })
        builder.addCase(fetchSegments.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })

        //Fetch Segments Config
        builder.addCase(fetchSegmentConfig.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(fetchSegmentConfig.fulfilled, (state, action) => {
            state.loading = 'idle'
            const formattedPayload = flattenObject(action.payload)
            state.segmentConfig = formattedPayload
        })
        builder.addCase(fetchSegmentConfig.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })

        // Create Segment Reducers
        builder.addCase(createSegment.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(createSegment.fulfilled, (state) => {
            state.loading = 'idle'
        })
        builder.addCase(createSegment.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })

        // Update Segment Reducers
        builder.addCase(updateSegment.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(updateSegment.fulfilled, (state) => {
            state.loading = 'idle'
        })
        builder.addCase(updateSegment.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })

        // Delete Segment Reducers
        builder.addCase(deleteSegment.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(deleteSegment.fulfilled, (state) => {
            state.loading = 'idle'
        })
        builder.addCase(deleteSegment.rejected, (state, action) => {
            state.loading = 'idle'
            state.error = action.error
        })

        // Add Segment Size Reducers
        builder.addCase(fetchSegmentSize.pending, (state) => {
            state.loading = 'pending'
            state.error = null
        })
        builder.addCase(fetchSegmentSize.fulfilled, (state, action) => {
            state.loading = 'idle'
            state.segmentSize = action.payload
        })
        builder.addCase(fetchSegmentSize.rejected, (state) => {
            state.loading = 'idle'
            state.error = null
            state.segmentSize = null
        })
    },
})

export const { addSegment, removeSegment, editSegment, clearSegmentSize } =
    segmentationSlice.actions

export default segmentationSlice.reducer
