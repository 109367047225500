import { Parse } from 'services/parse'
import { getIdFromGid } from 'utils/helpers/shopify'
import getHeaders from 'store/_utils/get-headers'

export function getSubscribers(appId) {
    const platformTypes = ['ios', 'android']
    return Parse.Cloud.run('notifications_getTopic', {
        appId,
        platformTypes,
    }).then((res) => {
        const iosSubs = res.ios.Attributes
            ? Number.parseInt(res.ios.Attributes.SubscriptionsConfirmed, 10)
            : 0
        const androidSubs = res.android.Attributes
            ? Number.parseInt(res.android.Attributes.SubscriptionsConfirmed, 10)
            : 0
        return { iosSubs, androidSubs }
    })
}

export async function fetchPushSubscribers(appId) {
    try {
        const parseApiUrl = import.meta.env.VITE_TC_PARSE_API
        const baseApiUrl = parseApiUrl.substring(
            0,
            parseApiUrl.lastIndexOf('/')
        )

        const headers = await getHeaders()
        const url = `${baseApiUrl}/notifications/${appId}/push-subscribers`

        const response = await fetch(url, {
            method: 'GET',
            headers,
        })
        const data = await response.json()

        if (!response.ok) {
            return {
                iosSubs: 0,
                androidSubs: 0,
            }
        }

        const iosSubs = data.ios_subscribers || 0
        const androidSubs = data.android_subscribers || 0
        return {
            iosSubs,
            androidSubs,
        }
    } catch (error) {
        return {
            iosSubs: 0,
            androidSubs: 0,
        }
    }
}

export function getScheduledNotifications(appId) {
    const App = Parse.Object.extend('Apps')
    const scheduledNotifQuery = new Parse.Query('Notifications')
    scheduledNotifQuery.equalTo('app', new App({ id: appId }))
    scheduledNotifQuery.equalTo('status', 'scheduled')
    scheduledNotifQuery.ascending('scheduled')
    return scheduledNotifQuery.find()
}

export function getPastNotifications(appId) {
    const App = Parse.Object.extend('Apps')
    const pastNotifquery = new Parse.Query('Notifications')
    pastNotifquery.equalTo('app', new App({ id: appId }))
    pastNotifquery.descending('updatedAt')
    pastNotifquery.notEqualTo('status', 'scheduled')
    pastNotifquery.limit(10)
    return pastNotifquery.find()
}

export async function getPastSegmentedNotifications(appId) {
    const headers = await getHeaders()

    const sort = 'updated_at:desc'
    const status = '!scheduled'

    return fetch(
        `${
            import.meta.env.VITE_TC_BACKEND_API
        }/push/custom-segment?sort=${sort}&status=${status}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
                'app-id': appId,
            },
        }
    ).then(async (response) => {
        if (!response.ok) {
            throw new Error('Failed to fetch segmented notifications')
        }
        const data = await response.json()
        return data
    })
}

export async function getScheduledSegmentedNotifications(appId) {
    const headers = await getHeaders()

    const sort = 'send_at:asc'
    const status = 'scheduled'

    return fetch(
        `${
            import.meta.env.VITE_TC_BACKEND_API
        }/push/custom-segment?sort=${sort}&status=${status}`,
        {
            method: 'GET',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
                'app-id': appId,
            },
        }
    ).then((response) => {
        if (!response.ok) {
            throw new Error('Failed to fetch scheduled segmented notifications')
        }
        return response.json()
    })
}

export const formatParseNotification = (notif) => {
    if (!notif) return null

    if (notif === 'Push notification already sent.  Duplicate blocked.') {
        return null
    }

    if (notif.msg) {
        return null
    }

    const notifData = notif.get('data')
    const notifScheduledDate = notif.get('scheduled')
    const notifCreatedAt = notif.get('createdAt')
    const formattedNotif = {}

    // Handle Legacy Notification Format
    if (notifData.aps) {
        formattedNotif.title = notifData.aps.alert.title
        formattedNotif.message = notifData.aps.alert.body
        formattedNotif.type = 'plain'
        formattedNotif.id = notif.id
        formattedNotif.createdAt = notifCreatedAt
        formattedNotif.scheduled = notifScheduledDate
        if (notifData.payload && notifData.payload.productId) {
            formattedNotif.attachment = notifData.payload.attachment
            formattedNotif.type = 'product'
            formattedNotif.productId = notifData.payload.productId
        }
        if (notifData.payload && notifData.payload.collectionId) {
            formattedNotif.attachment = notifData.payload.attachment
            formattedNotif.type = 'collection'
            formattedNotif.collectionId = notifData.payload.collectionId
        }
        return formattedNotif
    }
    formattedNotif.title = notifData.title
    formattedNotif.message = notifData.message
    formattedNotif.type = notifData.type
    formattedNotif.id = notif.id
    formattedNotif.createdAt = notifCreatedAt
    formattedNotif.scheduled = notifScheduledDate
    formattedNotif.attachment = notifData.attachment
    formattedNotif.destination = notifData.destination
    if (notifData.type === 'product') {
        formattedNotif.productId = notifData.productId
    }
    if (notifData.type === 'collection') {
        formattedNotif.collectionId = notifData.collectionId
    }
    return formattedNotif
}

export const createPushPayload = (push, appId) => {
    const payload = {
        live: 'true',
        title: push.title || '',
        message: push.message || '',
        appId,
        platformTypes: ['ios', 'android'],
        type: 'plain',
        scheduled: push.scheduledDate ? push.scheduledDate.toJSDate() : null,
        attachment: push.attachment,
        destination: push.destination,
    }

    if (push.type === 'collection') {
        payload.type = 'collection'
        payload.collectionId = String(getIdFromGid(push.collectionId))
    }
    if (push.type === 'product') {
        payload.type = 'product'
        payload.productId = String(getIdFromGid(push.productId))
    }
    if (push.type === 'custom') {
        payload.type = 'custom'

        if (push.destination?.type === 'web') {
            payload.destination = {
                ...push.destination,
                url: formatURL(push.destination.url),
            }
        }
    }

    // Adds segment information for segmented push payload creation
    if (push.customSegmentId) {
        payload.pushType = 'custom_segment_individual'
        payload.customSegmentId = push.customSegmentId
        payload.destination = push.destination.url
    }
    return payload
}

const formatURL = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url
    }
    return `https://${url}`
}
