/* eslint-disable object-shorthand */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Parse } from 'services/parse'
import { formatNumber } from 'utils/helpers/misc'
import { checkResponseForError } from 'pages/Push/utils'
import getHeaders from 'store/_utils/get-headers'
import {
    getScheduledNotifications,
    getPastNotifications,
    getSubscribers,
    formatParseNotification,
    createPushPayload,
    fetchPushSubscribers,
    getPastSegmentedNotifications,
    getScheduledSegmentedNotifications,
} from './helpers'

const pushAPI = `${import.meta.env.VITE_TC_INSIGHTS_PRO_API}/notifications`

export const sendTestNotification = createAsyncThunk(
    'notifications/sendTestNotification',
    async (pushFields, { getState }) => {
        const state = getState()
        const headers = await getHeaders()
        const appId = state.app?.data?.id
        const { deviceId } = pushFields

        try {
            const response = await fetch(
                `${
                    import.meta.env.VITE_TC_BACKEND_API
                }/push/sendTest/${appId}/${deviceId}`,
                {
                    method: 'POST',
                    headers: {
                        ...headers,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(pushFields),
                }
            )
            return response
        } catch (error) {
            throw new Error(error)
        }
    }
)

export const sendNotification = createAsyncThunk(
    'notifications/sendNotification',
    async (notificationSettings, { getState }) => {
        const state = getState()
        const appId = state.app?.data?.id

        const pushPayload = createPushPayload(notificationSettings, appId)
        const res = await Parse.Cloud.run('notifications_sendPush', pushPayload)

        checkResponseForError(res)
        return res
    }
)

export const sendScheduledNotification = createAsyncThunk(
    'notifications/sendScheduledNotification',
    async (notificationSettings, { getState }) => {
        const state = getState()
        const appId = state.app?.data?.id

        const pushPayload = createPushPayload(notificationSettings, appId)
        const res = await Parse.Cloud.run(
            'notifications_schedulePush',
            pushPayload
        )

        checkResponseForError(res)

        return res
    }
)

export const editScheduledNotification = createAsyncThunk(
    'notifications/editScheduledNotification',
    async (notificationSettings, { getState }) => {
        const state = getState()
        const appId = state.app?.data?.id
        const headers = await getHeaders()

        const pushPayload = createPushPayload(notificationSettings, appId)
        const res = await fetch(`${pushAPI}/${notificationSettings.id}`, {
            method: 'PUT',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: JSON.stringify({
                data: pushPayload,
                appId: pushPayload.appId,
            }),
        }).then((response) => response.json())

        checkResponseForError(res)

        // eslint-disable-next-line no-underscore-dangle
        return { id: res._id, scheduled: new Date(res.scheduled), ...res.data }
    }
)

export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (_, { getState }) => {
        const state = getState()
        const appId = state.app?.data?.id

        return Promise.all([
            getScheduledNotifications(appId),
            getPastNotifications(appId),
            fetchPushSubscribers(appId),
            getPastSegmentedNotifications(appId),
            getScheduledSegmentedNotifications(appId),
        ]).then(
            ([
                scheduledNotifications,
                pastNotifications,
                subscribers,
                pastSegmentedNotifications,
                scheduledSegmentedNotifications,
            ]) => ({
                scheduledNotifications: scheduledNotifications.map(
                    (notification) => formatParseNotification(notification)
                ),
                pastNotifications: pastNotifications.map((notification) =>
                    formatParseNotification(notification)
                ),
                subscribers,
                pastSegmentedNotifications,
                scheduledSegmentedNotifications,
            })
        )
    }
)

export const deleteScheduledNotification = createAsyncThunk(
    'notifications/deleteScheduledNotification',
    async (notificationId) => {
        const ParseNotification = Parse.Object.extend('Notifications')
        const query = new Parse.Query(ParseNotification)

        await query
            .get(notificationId)
            .then((notification) => {
                // The object was retrieved successfully.
                notification.destroy()
            })
            .catch((error) => {
                // The object was not retrieved successfully.
                // error is a Parse.Error with an error code and description.
                throw error
            })
        return notificationId
    }
)

export const deleteSegmentedPush = createAsyncThunk(
    'notifications/deleteSegmentedPush',
    async (notificationId, { getState }) => {
        const state = getState()
        const appId = state.app?.data?.id
        const headers = await getHeaders()

        const response = await fetch(
            `${
                import.meta.env.VITE_TC_BACKEND_API
            }/push/custom-segment/${notificationId}`,
            {
                method: 'DELETE',
                headers: {
                    ...headers,
                    'Content-Type': 'application/json',
                    'app-id': appId,
                },
            }
        )

        if (!response.ok) {
            throw new Error('Failed to delete segmented notification')
        }

        return notificationId
    }
)

const notifications = createSlice({
    name: 'notifications',
    initialState: {
        loading: 'idle',
        initialized: false,
        error: undefined,
        data: {
            scheduledNotifications: [],
            pastNotifications: [],
            subscribers: {
                iosSubs: 0,
                androidSubs: 0,
            },
            pastSegmentedNotifications: [],
            scheduledSegmentedNotifications: [],
        },
    },
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.loading = 'pending'
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.data = action.payload
            state.loading = 'fufilled'
            state.error = undefined
            state.initialized = true
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
        [sendNotification.pending]: (state) => {
            state.loading = 'pending'
        },
        [sendNotification.fulfilled]: (state, action) => {
            const { pastNotifications } = state.data
            const newPastNotifications = [
                formatParseNotification(action.payload),
                ...pastNotifications,
            ]

            state.data.pastNotifications = newPastNotifications
            state.loading = 'fufilled'
            state.error = undefined
        },
        [sendNotification.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
        [sendScheduledNotification.pending]: (state) => {
            state.loading = 'pending'
        },
        [sendScheduledNotification.fulfilled]: (state, action) => {
            const { scheduledNotifications } = state.data
            const newScheduledNotifications = [
                formatParseNotification(action.payload),
                ...scheduledNotifications,
            ]

            state.data.scheduledNotifications = newScheduledNotifications
            state.loading = 'fufilled'
            state.error = undefined
        },
        [sendScheduledNotification.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
        [deleteScheduledNotification.pending]: (state) => {
            state.loading = 'pending'
        },
        [deleteScheduledNotification.fulfilled]: (state, action) => {
            const { scheduledNotifications } = state.data
            const newScheduledNotifications = scheduledNotifications.filter(
                (notification) => notification.id !== action.payload
            )

            state.data.scheduledNotifications = newScheduledNotifications
            state.loading = 'fufilled'
            state.error = undefined
        },
        [deleteScheduledNotification.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
        [editScheduledNotification.pending]: (state) => {
            state.loading = 'pending'
        },
        [editScheduledNotification.fulfilled]: (state, action) => {
            const { scheduledNotifications } = state.data
            const newScheduledNotifications = scheduledNotifications.map(
                (notification) =>
                    notification.id === action.payload.id
                        ? action.payload
                        : notification
            )

            state.data.scheduledNotifications = newScheduledNotifications
            state.loading = 'fufilled'
            state.error = undefined
        },
        [editScheduledNotification.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
        [deleteSegmentedPush.pending]: (state) => {
            state.loading = 'pending'
        },
        [deleteSegmentedPush.fulfilled]: (state, action) => {
            const {
                pastSegmentedNotifications,
                scheduledSegmentedNotifications,
            } = state.data

            // Remove from past notifications if present
            if (pastSegmentedNotifications) {
                state.data.pastSegmentedNotifications =
                    pastSegmentedNotifications.filter(
                        (notification) => notification.id !== action.payload
                    )
            }

            // Remove from scheduled notifications if present
            if (scheduledSegmentedNotifications) {
                state.data.scheduledSegmentedNotifications =
                    scheduledSegmentedNotifications.filter(
                        (notification) => notification.id !== action.payload
                    )
            }

            state.loading = 'fufilled'
            state.error = undefined
        },
        [deleteSegmentedPush.rejected]: (state, action) => {
            state.loading = 'rejected'
            state.error = action.error
        },
    },
})

export const selectScheduledNotifications = (state) =>
    state.notifications.data.scheduledNotifications

export const selectPastNotifications = (state) =>
    state.notifications.data.pastNotifications

export const selectTotalSubscribers = (state) => {
    const { iosSubs, androidSubs } = state.notifications.data.subscribers
    return formatNumber(iosSubs + androidSubs)
}

export const selectPastSegmentedNotifications = (state) =>
    state.notifications.data.pastSegmentedNotifications

export const selectScheduledSegmentedNotifications = (state) =>
    state.notifications.data.scheduledSegmentedNotifications

export default notifications.reducer
